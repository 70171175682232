import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { ApiRes } from "../../../Utiles/types";
import { fetchApi } from "../../../Utiles/connections";
import Loader3 from "../../Shared/Loader3/Loader3";
import { NavigateTo } from "../../../Utiles/functions";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    token: state.token,
    ltoken: state.ltoken,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setToken: (token: string) =>
      dispatch({ type: "SET_TOKEN", payload: token }),
    setLToken: (ltoken: string) =>
      dispatch({ type: "SET_LTOKEN", payload: ltoken }),
    setUser: (user: any) => dispatch({ type: "SET_USER", payload: user }),
  };
};

const Login = ({ path, setPath, setToken, setLToken, setUser }: any) => {
  const navigate = useNavigate();
  const [Email, setEmail] = React.useState<string>("");
  const [Password, setPassword] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleNavigate = (path: string) => {
    navigate(path);
    setPath(path);
  };

  const login = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response: ApiRes = await fetchApi("AUTH/MemberLogin", "POST", {
      Email,
      Password,
    });
    if (response.StatusCode === 200) {
      if (response.My_Result.VerifiedEmail) {
        if (response.My_Result.IdLawyer !== null) {
          setLToken(response.My_Result.JWT_Token);
        }
        setToken(response.My_Result.JWT_Token);
        setUser({ ...response.My_Result, Email });
        console.log({ ...response.My_Result, Email });

        handleNavigate("/");
      } else {
        const result = await fetchApi(
          "Auth/SendMemberVerificationEmail",
          "POST",
          {
            Email,
          }
        );
        if (result.StatusCode === 400 || result.StatusCode === 200) {
          handleNavigate("/verification");
          console.log(response.My_Result);
          setUser({ ...response.My_Result, Email });
        } else {
          alert("Something went wrong, please try again");
        }
      }
    } else {
      alert(response.ExceptionMsg);
    }
    setLoading(false);
  };

  const reSend = async () => {};

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  return (
    <div className="membersLogin flex-column ">
      <div
        className="card shadow border-0 rounded-border p-3 text-center blur text-white"
        style={{ width: "30rem", maxWidth: "90%" }}>
        <h1>Login</h1>
        <form className="d-flex flex-column" onSubmit={login}>
          <div className="">
            <label className="text-start w-100 mt-2 mb-1" htmlFor="Email">
              Email
            </label>
            <input
              name="Email"
              type="Email"
              placeholder="Email"
              className="form-control "
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <label className="text-start w-100 mt-2 mb-1" htmlFor="password">
            Password
          </label>
          <div className=" d-flex align-items-center mb-2 w-100 position-relative">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="form-control"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <i
              className={`cursor-pointer text-dark bi bi-eye${
                showPassword ? "" : "-slash"
              }`}
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "0.6rem",
              }}></i>
          </div>
          <div className="d-flex justify-content-end">
            <a onClick={() => handleNavigate("/forgot-password")}>
              Forgot Password?
            </a>
          </div>
          <button
            className="navIcons w-100 mb-3 mt-2 d-flex justify-content-center py-2 "
            type="submit">
            {loading ? <Loader3 /> : "Login"}
          </button>
        </form>

        <div className="d-flex justify-content-center">
          <a onClick={() => handleNavigate("/signup")}>Create an account</a>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
